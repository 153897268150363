<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="20" lg="8" xl="6">
        <div class="d-flex justify-space-between align-center mb-4">
          <h2 class="mr-auto">Tradie Details</h2>

          <v-btn
            :to="{
              name: 'new-tradie-certification',
              params: { id: $attrs.id },
            }"
            :disabled="form.$busy"
            class="mr-6 px-5 back-button"
            text
          >
            <v-icon> {{ icons.back }} </v-icon>
            <span class="text-decoration-underline"> Go Back </span>
          </v-btn>

          <v-btn
            class="bg-primary-gradient primary next-button px-12"
            :disabled="form.$busy || loadingInitValues"
            :loading="form.$busy || loadingInitValues"
            @click="saveAndNext"
            depressed
            text
          >
            <span> Next </span>
            <v-icon> {{ icons.next }} </v-icon>
          </v-btn>
        </div>

        <div>
          <v-alert text type="success">
            Tradie's password is auto generated. Password will be sent as an
            email once tradie profile is activated.
          </v-alert>

          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.primary_contact_name"
                label="Primary Contact Name"
                :error-messages="form.$getError('primary_contact_name')"
                :disabled="form.$busy || loadingInitValues"
                outlined
                flat
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.email"
                label="Email Address"
                :error-messages="form.$getError('email')"
                :disabled="form.$busy || loadingInitValues"
                outlined
                flat
              />
            </v-col>
          </v-row>
        </div>

        <v-divider />

        <div class="mt-8">
          <h2 class="mb-5">Bank Details</h2>

          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.bank_name"
                label="Bank Name"
                :error-messages="form.$getError('bank_name')"
                :disabled="form.$busy || loadingInitValues"
                outlined
                flat
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.bank_account_name"
                :error-messages="form.$getError('bank_account_name')"
                :disabled="form.$busy || loadingInitValues"
                label="Bank Account Name"
                outlined
                flat
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.bank_state_branch"
                :error-messages="form.$getError('bank_state_branch')"
                :disabled="form.$busy || loadingInitValues"
                label="BSB"
                outlined
                flat
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-mask="'#################################################'"
                :error-messages="form.$getError('bank_account_number')"
                :disabled="form.$busy || loadingInitValues"
                v-model="form.bank_account_number"
                label="Bank Account Number"
                outlined
                flat
              />
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Tradie from '@/models/Admin/Tradie'
import Form from '@/utils/form'

import { get, pick, first, extend, isEmpty } from 'lodash'
import { mdiArrowRight, mdiArrowLeft } from '@mdi/js'
import { mapActions, mapMutations, mapState } from 'vuex'
import { mask } from 'vue-the-mask'

export default {
  name: 'NewTradieDetails',

  directives: { mask },

  data() {
    return {
      loadingInitValues: true,

      form: new Form({
        id: null,
        email: null,
        primary_contact_name: null,
        bank_name: null,
        bank_account_name: null,
        bank_state_branch: null,
        bank_account_number: null,
      }),

      icons: {
        back: mdiArrowLeft,
        next: mdiArrowRight,
      },
    }
  },

  computed: {
    ...mapState('newTradie', ['tradie']),

    withNewTradie() {
      return !!this.$attrs.id
    },
  },

  created() {
    this.initValues()
  },

  methods: {
    ...mapActions('newTradie', ['getTradieDetails']),
    ...mapMutations('newTradie', ['setNewTradie']),

    async initValues() {
      if (this.withNewTradie && !this.tradie.tradie_id) {
        await this.getTradieDetails(this.$attrs.id)
      }

      this.setFormData()

      this.loadingInitValues = false
    },

    async setFormData() {
      const tradie = pick(this.tradie, ['id', 'user', 'primary_contact_name'])
      const bankAccounts = get(tradie, 'user.bank_accounts', [])

      this.form = extend(this.form, tradie)

      if (!isEmpty(get(tradie, 'user', null))) {
        this.form.email = tradie.user.email
      }

      if (!isEmpty(bankAccounts)) {
        const _bank = pick(first(bankAccounts), [
          'bank_name',
          'bank_account_name',
          'bank_state_branch',
          'bank_account_number',
        ])

        this.form = extend(this.form, _bank)
      }

      this.form = new Form(this.form.$data())
    },

    saveAndNext(redirect = null) {
      if (this.form.$busy) {
        return
      }

      this.form.$clearErrors()
      this.form.$busy = true

      const tradie = new Tradie(this.form.$data())

      tradie
        .save()
        .then(async ({ data }) => {
          await this.setNewTradie(data)

          this.$router.push({
            name: 'new-business-details',
            params: { id: this.$attrs.id },
          })
        })
        .catch(({ response }) => {
          this.form.$wait(() => {
            if (response && response.status === 422) {
              this.form.$setErrors(response.data.errors)
            }

            this.form.$busy = false
          })
        })
    },
  },
}
</script>
